
















































































































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, computed } from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomAnchor,
  AtomAnchorSizeEnum,
  AtomAnchorTypeEnum,
} from "@/v2/new-design-system";
import { FacebookCommerceSettings } from "../settings";
import { t } from "@/i18n";

const css = bemBuilder("facebook-commerce-connected");

export default defineComponent({
  name: "FacebookCommerceConnected",
  components: {
    AtomText,
    AtomAnchor,
    FacebookCommerceSettings,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    businessManagerId: {
      type: String,
      default: undefined,
    },
    pixelId: {
      type: String,
      default: undefined,
    },
  },
  emits: {
    uninstalled: () => true,
  },
  setup(props) {
    const setupLink = "https://docs.chatfood.io/en/articles/5190405";
    const managerLink = "https://business.facebook.com/commerce_manager";
    const verifyDomainLink = "https://docs.chatfood.io/en/articles/5289712";

    const statusLabel = computed(() =>
      props.businessManagerId
        ? t("module.facebook_commerce.connected.installed_label")
        : t("module.facebook_commerce.connected.connected_label")
    );

    return {
      t,
      css,
      statusLabel,
      setupLink,
      managerLink,
      verifyDomainLink,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomAnchorSizeEnum,
      AtomAnchorTypeEnum,
    };
  },
});
