























import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, onMounted } from "@vue/composition-api";
import { OrgHeaderInfo } from "@/v2/new-design-system";
import { FacebookCommerceStart } from "../../component/start";
import { FacebookCommerceConnected } from "../../component/connected";
import { facebookCommerceRepo } from "../../repo";
import { t } from "@/i18n";

const css = bemBuilder("facebook-commerce-main");

export default defineComponent({
  name: "FacebookCommerceMain",
  components: {
    OrgHeaderInfo,
    FacebookCommerceStart,
    FacebookCommerceConnected,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isConnected = ref(false);
    const pixelId = ref();
    const businessManagerId = ref();

    onMounted(getMetaBusinessExtension);

    async function getMetaBusinessExtension() {
      const res = await facebookCommerceRepo.getSettings({
        businessId: props.businessId,
      });

      if (res) {
        isConnected.value = res.isEnabled;
      }
    }

    return {
      t,
      css,
      isConnected,
      pixelId,
      businessManagerId,
    };
  },
});
