import camelcaseKeys from "camelcase-keys";
import { IApiContract } from "./api-contract.type";
import { IResponse } from "./response.type";

export const responseTransformer = (apiResponse: IApiContract): IResponse => {
  return {
    ...camelcaseKeys(apiResponse, { deep: true }),
    pixelId: apiResponse.pixel_id ?? undefined,
    businessManagerId: apiResponse.business_manager_id ?? undefined,
    catalogueId: apiResponse.catalogue_id ?? undefined,
  };
};
