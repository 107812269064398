import {
  fetcher,
  FetcherHTTPError,
  FetcherConnectionLostError,
} from "@/core/fetcher";
import { IRequest } from "./request.type";
import { IResponse } from "./response.type";
import { IApiContract } from "./api-contract.type";
import { responseTransformer } from "./response-transformer";
import { FacebookCommerceRepoError } from "../repo-error";
import { report } from "@chatfood/bug-reporter";

/**
 * {@link https://chatfood.stoplight.io/docs/chatfood-api-dashboard/branches/main/8fc4046082b54-get-meta-business-extension-of-a-business API Contract}
 */
export async function fetchMetaBusinessExtension(
  request: IRequest
): Promise<IResponse> {
  try {
    const res = await fetcher.get<IApiContract>(
      `v1/businesses/${request.businessId}/meta-business-extension/configurations`
    );

    return responseTransformer(res);
  } catch (err: unknown) {
    if (!(err instanceof FetcherConnectionLostError)) {
      const responseStatusCode =
        err instanceof FetcherHTTPError ? err.status : 1000;

      report(err, {
        tags: {
          "http.response_code": responseStatusCode,
        },
        context: {
          "Serialized error": JSON.stringify(err),
        },
      });
    }

    throw new FacebookCommerceRepoError(
      "fetchMetaBusinessExtension",
      "An unexpected error happened",
      "unknown",
      err
    );
  }
}
