



































































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref } from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  MolIconButton,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import { facebookCommerceRepo } from "../../repo";
import { report } from "@chatfood/bug-reporter";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { t } from "@/i18n";

const css = bemBuilder("facebook-commerce-settings");

export default defineComponent({
  name: "FacebookCommerceSettings",
  components: {
    AtomText,
    AtomButton,
    MolIconButton,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    businessManagerId: {
      type: String,
      default: undefined,
    },
    pixelId: {
      type: String,
      default: undefined,
    },
  },
  emits: {
    uninstalled: () => true,
  },
  setup(props, { emit }) {
    const showSettings = ref(false);
    const isUninstalling = ref(false);

    async function onUninstall() {
      try {
        isUninstalling.value = true;
        await facebookCommerceRepo.uninstall({ businessId: props.businessId });
        new Toast().create({
          type: "success",
          text: t(
            "module.facebook_commerce.settings.success_uninstall_message"
          ),
        });
        emit("uninstalled");
      } catch (e) {
        report(e);
        generalErrorToast();
      } finally {
        isUninstalling.value = false;
      }
    }

    return {
      t,
      css,
      onUninstall,
      showSettings,
      isUninstalling,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
    };
  },
});
